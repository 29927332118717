@import "./Global.scss";

.appLayout {
  position: relative;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  min-height: 100vh;
  width: 100%;

  background-color: #FCFCFC;
  background-position: center;
  background-image: url("../Images/Background.jpg");
  background-size: cover;
  
  
  // we dont use the overlay on this project
  .bg-overlay {
    position: absolute;
    top: 0;left: 0;right: 0;bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(252, 252, 252, 0.466);
    z-index: 1;
  }

  > *:not(.bg-overlay) {
    z-index: 10;
  }
  
  .Header {
    display: grid;
    place-items: center;

    height: 100px;
    width: 100px;
    
    background-color: $primary-color;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.12);
    border-radius: 0 0 25px 0;

    cursor: pointer;

    &:hover {
      transition: all .2s;
      background-color: $secondary-color;
    }
    
    img {
      height: 50px;
    }
  }

  .App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .mobile-actions {
      display: none;
    }
    
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      min-height: calc(100vh - 80px);

      .app-page-container, .app-page-wrap-container {
        min-height: unset;
      }
      
      .app-page-btn {
        display: none;
      }

      .mobile-actions {
        display: flex;
        
        .app-page-btn {
          display: grid;
        }
      }
    }




    //Containers
    .ilustration {
      border-radius: 15px 95px 15px 15px;
      width: min(100%, 350px);
      max-height: 298px;
      object-fit: cover;

      @media screen and (max-width: 700px) {
        border-radius: 25px !important;
      }
    }

    .app-page-wrap-container {
      display: flex;
      justify-content: center;
      align-items: center;
      
      margin-bottom: 50px;
      width: 80%;
      min-height: calc(100vh - 80px);
      
      @media screen and (max-width: 1000px) {
        margin-top: 50px;
        margin-bottom: 0;
      }
    }






    
    
    
    /* Ejemplo de animación */
    .animated-div {
      /* Estilos comunes */
      transition: all 0.5s ease-in-out; /* Transición para cambiar de estilo */

      /* Estilos específicos que cambiarán con la animación */
      transform: scale(1); /* Escala inicial */
    }

    .animated-div.animate {
      transform: scale(1.01); /* Escala aumentada durante la animación */
    }

    .app-page-container {
      display: grid;
      grid-template-columns: 500px 1fr;
      grid-template-rows: 1fr;
        
      width: 100%;
      height: 80vh;
      background-color: #E2D2C3;
      box-shadow: 0px 0px 20px 14px rgb(0 0 0 / 11%);
      border-radius: 25px;
      overflow: hidden;



      .div1 { grid-area: 1 / 1 / 2 / 2; }
      .div2 { grid-area: 1 / 2 / 2 / 3; }







      .cardex-profile-wrap {
        overflow: hidden;
        
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        // w
      }















      

      .cardex-info-wrapper {
        position: relative;
        height: 100%;
        width: 100%;
        
        .cardex-info-solapa {
          z-index: 10;
          position: absolute;
          left: -6vw;
          height: 80vh;
        }
        

        .cardex-info-circulo {
          z-index: 10;
          position: absolute;
          top: 3vh;
          width: 2vw;

          &.c1 {right: 3vw;}
          &.c2 {right: 8vw;}
          &.c3 {right: 13vw;}
          &.c4 {right: 18vw;}
        }


        .cardex-info-estrella {
          z-index: 10;
          position: absolute;
          bottom: 3vh;
          right: 3vw;
          width: 3.5vw;
        }


        .cardex-info-multiplesCirculos {
          z-index: 10;
          position: absolute;
          left: 1vw;
          width: 2.5vw;

          &.m1{ bottom: 3vh; }
          &.m2{ bottom: 15vh; }
          &.m3{ bottom: 27vh; }
        }



        .cardex-info {
          display: flex;
          flex-direction: column;
          justify-content: center;

          width: 100%;
          height: 100%;
          padding: 3vh 4vw 12vh;

          
          .asesor-wrapper {
            display: grid;
            grid-template-columns: 5vw 1fr;
            grid-template-rows: repeat(2, 1fr);
            gap: 1vw;
              
            .isotipo {
              grid-area: 1 / 1 / 3 / 2;
              width: 5vw;
            }
            
            h1, h2 {
              display: flex;
              align-self: end;

              color: #000;
              font-family: "Libre Baskerville", serif;
              font-weight: 700;
              font-style: normal;
            }
            h1 {
              grid-area: 2 / 2 / 3 / 3;
              font-size: 2.5vw;
              line-height: 3vw;
            }
            h2 {
              grid-area: 1 / 2 / 2 / 3;
              font-size: 2vw;
              line-height: 2vw;
            }
          }



          .cardex-info-medallas {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(2, 10vh);
            gap: 5vh;
            place-items: center;

            margin-top: 5vh;

            .c-medalla {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              
              width: 10vh;
              height: 10vh;
              border-radius: 50%;
              background-color: #fff;

              font-size: 3vw;

              .c-nombre {
                font-size: 12px;
                text-align: center;
              }

              &.disabled {
                opacity: .3;
              }

              &.m-vida {
                // Definir variables para el color dorado y el ángulo del gradiente
                $color-oro: #ffffa4;
                $gradiente-angulo: 45deg;

                // Definir el estilo del div
                background: $color-oro;
                background: linear-gradient($gradiente-angulo, $color-oro, lighten($color-oro, 10%));
                box-shadow: 0 0 20px rgba(255, 255, 224, 0.5); // Sombra de caja dorada
                position: relative;

                // Animación de brillo
                @keyframes brillo {
                  0% {
                    transform: scale(1);
                  }
                  50% {
                    transform: scale(1.1);
                  }
                  100% {
                    transform: scale(1);
                  }
                }

                animation: brillo 2s infinite alternate;
              }

              &.m-gmm {
                // Definir variables para el color rojo más claro y el ángulo del gradiente
                $color-rojo-claro: #FFA07A;
                $gradiente-angulo: 45deg;

                // Estilo del div con fondo rojo claro, brillo y sombra de caja
                background: $color-rojo-claro;
                background: linear-gradient($gradiente-angulo, $color-rojo-claro, lighten($color-rojo-claro, 10%));
                box-shadow: 0 0 20px rgba(255, 192, 203, 0.5); // Sombra de caja roja clara
                position: relative;

                // Animación de brillo
                @keyframes brillo {
                  0% {
                    transform: scale(1);
                  }
                  50% {
                    transform: scale(1.1);
                  }
                  100% {
                    transform: scale(1);
                  }
                }

                animation: brillo 2s infinite alternate;

              }

              &.m-25pts {
                // Definir variables para el color azul y el ángulo del gradiente
                $color-azul: #6babff;
                $gradiente-angulo: 45deg;

                // Estilo del div con fondo azul, brillo y sombra de caja
                background: $color-azul;
                background: linear-gradient($gradiente-angulo, $color-azul, lighten($color-azul, 10%));
                box-shadow: 0 0 20px rgba(173, 216, 230, 0.5); // Sombra de caja azul
                position: relative;

                // Animación de brillo
                @keyframes brillo {
                  0% {
                    transform: scale(1);
                  }
                  50% {
                    transform: scale(1.1);
                  }
                  100% {
                    transform: scale(1);
                  }
                }

                animation: brillo 2s infinite alternate;
              }

              &.m-acompanador {
                // Definir variables para el color rosa y el ángulo del gradiente
                $color-rosa: #FFC0CB;
                $gradiente-angulo: 45deg;

                // Estilo del div con fondo rosa, brillo y sombra de caja
                background: $color-rosa;
                background: linear-gradient($gradiente-angulo, $color-rosa, lighten($color-rosa, 10%));
                box-shadow: 0 0 20px rgba(255, 192, 203, 0.5); // Sombra de caja rosa
                position: relative;

                // Animación de brillo
                @keyframes brillo {
                  0% {
                    transform: scale(1);
                  }
                  50% {
                    transform: scale(1.1);
                  }
                  100% {
                    transform: scale(1);
                  }
                }

                animation: brillo 2s infinite alternate;

              }

              &.m-activo {
                // Definir variables para el color turquesa y el ángulo del gradiente
                $color-turquesa: #AFEEEE;
                $gradiente-angulo: 45deg;

                // Estilo del div con fondo turquesa, brillo y sombra de caja
                background: $color-turquesa;
                background: linear-gradient($gradiente-angulo, $color-turquesa, lighten($color-turquesa, 10%));
                box-shadow: 0 0 20px rgba(175, 238, 238, 0.5);; // Sombra de caja turquesa
                position: relative;

                // Animación de brillo
                @keyframes brillo {
                  0% {
                    transform: scale(1);
                  }
                  50% {
                    transform: scale(1.1);
                  }
                  100% {
                    transform: scale(1);
                  }
                }

                animation: brillo 2s infinite alternate;
              }

              &.m-congresista {
                // Definir variables para el color morado y el ángulo del gradiente
                $color-morado-claro: #d4a0ff;
                $gradiente-angulo: 45deg;

                // Estilo del div con fondo morado, brillo y sombra de caja
                background: $color-morado-claro;
                background: linear-gradient($gradiente-angulo, $color-morado-claro, lighten($color-morado-claro, 10%));
                box-shadow: 0 0 20px rgba(224, 187, 255, 0.5); // Sombra de caja morada
                position: relative;

                // Animación de brillo
                @keyframes brillo {
                  0% {
                    transform: scale(1);
                  }
                  50% {
                    transform: scale(1.1);
                  }
                  100% {
                    transform: scale(1);
                  }
                }

                animation: brillo 2s infinite alternate;  
              }

              &.m-cabos {
                // Definir variables para el color naranja suave y el ángulo del gradiente
                $color-naranja-suave: #ffc38f;
                $gradiente-angulo: 45deg;

                // Estilo del div con fondo naranja suave, brillo y sombra de caja
                background: $color-naranja-suave;
                background: linear-gradient($gradiente-angulo, $color-naranja-suave, lighten($color-naranja-suave, 10%));
                box-shadow: 0 0 20px rgba(255, 218, 185, 0.5); // Sombra de caja naranja suave
                position: relative;

                // Animación de brillo
                @keyframes brillo {
                  0% {
                    transform: scale(1);
                  }
                  50% {
                    transform: scale(1.1);
                  }
                  100% {
                    transform: scale(1);
                  }
                }

                animation: brillo 2s infinite alternate;

              }

              &.m-noveles {
                $color-verde-pastel: #98fb98; // Color verde pastel
                $gradiente-angulo: 45deg;
            
                background: $color-verde-pastel;
                background: linear-gradient($gradiente-angulo, $color-verde-pastel, lighten($color-verde-pastel, 10%));
                box-shadow: 0 0 20px rgba(152, 251, 152, 0.5); // Sombra de caja verde pastel
                position: relative;
            
                @keyframes brillo {
                    0% {
                        transform: scale(1);
                    }
                    50% {
                        transform: scale(1.1);
                    }
                    100% {
                        transform: scale(1);
                    }
                }
            
                animation: brillo 2s infinite alternate;
              }
            
            }

           
          }
        }


      }


    }























    .app-page-btn {
      display: grid;
      place-items: center;
      
      margin: 24px;
      width: 56px;
      height: 56px;
      
      border-radius: 50%;
      background-color: #282828;

      .sbi {
        font-size: 14px;
        font-weight: 700;
        color: #0F70D4;
      }
    }

    .radio_group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 8px;

      width: 100%;
      padding: 16px 26px;

      @media screen and (max-width: 1000px) {
        padding: 16px 0;
      }
    }

    .radio_field {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      
      width: 100%;
      padding: 10px 16px;

      border-radius: 8px;
      border: 1px solid #f2f4f8;
      
      cursor: pointer;

      label {
        font-size: clamp(15px,1.5vw, 17px);
        color: #e5e5e5;
      }

      .p-radiobutton-box {
        background: transparent !important;
      }
    }




  }
}


// Checkbox Primereact component
.Checkbox {
  display: flex;
  align-items: center;
  gap: 5px;

  label {
    font-size: clamp(14px, 2vw, 18px);
    font-weight: 500;
    
    color: #c4c4c4;
    line-height: clamp(1.1rem, 3vw, 1.5rem);

    cursor: pointer;
  }
}


// Botones que hago con iconos de primeicons dentro
.PiButton {
  margin: 25px 0 50px;
  padding: 20px;
  border-radius: 50%;
  border: none;
  outline: none;

  background-color: $primary-color;
  color: white;

  cursor: pointer;
  transition: all .3s;

  &:hover {
    transition: all .3s;
    transform: scale(1.1);
  }

  &.PiButtonDisabled {
    opacity: .2;
    cursor: unset;
  }
}



















.step-btn {
  display: grid;
  place-items: center;
  
  margin: 24px;
  width: 56px;
  height: 56px;
  
  border-radius: 50%;
  background-color: #282828;

  .sbi {
    font-size: 14px;
    font-weight: 700;
    color: $primary-color;
  }
}

.radio_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;

  width: 100%;
  padding: 16px 26px;

  @media screen and (max-width: 1000px) {
    padding: 16px 0;
  }
}

.radio_field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  
  width: 100%;
  padding: 10px 16px;

  border-radius: 8px;
  border: 1px solid #f2f4f8;
  
  cursor: pointer;

  label {
    font-size: clamp(15px,1.5vw, 17px);
    color: #e5e5e5;
  }

  .p-radiobutton-box {
    background: transparent !important;
  }
}



